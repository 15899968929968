<template>
  <main>
    <h2>Members</h2>
    <div class="row">
      <Profile
        v-for="(member, index) in memberList.slice(0, 2)"
        :key="index"
        :member="member"
      />
    </div>
    <div class="row">
      <Profile
        v-for="(member, index) in memberList.slice(2, 4)"
        :key="index"
        :member="member"
      />
    </div>
    <div class="row">
      <Profile
        v-for="(member, index) in memberList.slice(4, 7)"
        :key="index"
        :member="member"
      />
    </div>
  </main>
</template>

<script>
import Profile from "@/components/Profile";

export default {
  name: "Members",
  components: { Profile },
  data() {
    return {
      memberList: [
        {
          src: require("../assets/sample_b.png"),
          name: "Jay Lee",
          title: "Co CEO",
          intro:
            "Jay majored in computer science and executed the largest OTC volumes in Korea. He also operated the largest custody AUC domestically at DXM (subsidiary of Dunamu). As co-founder of Trinito, he is in charge of the trading business.",
        },
        {
          name: "Joseph Jo",
          title: "CTO",
          intro:
            "Joseph is an experienced developer with a demonstrated expertise of and successful achievements in the gaming industry. He has led teams of DeFi experts and solidity/ react developers. Joseph studied computer engineering at Seoul National University.",
        },
        {
          name: "Oscar Oh",
          title: "Developer",
          intro:
            "Oscar started his career as a software engineer at a cryptocurrency exchange. He has been developing futures and spot trading systems for  the quant team. Oscar is especially interested in software architecture and performance. He is committed to staying at the forefront of the rapidly evolving blockchain industry.",
        },
        {
          name: "Ethan Han",
          title: "Developer",
          intro:
            "Ethan started his career as a startup and worked as a front-end engineer for a blockchain company. He majored in Electrical and Computer Engineering.",
        },
        {
          name: "Jiyon Kim",
          title: "Associate",
          intro:
            "Jiyon graduated with a degree with Marketing and expanded her studies into Big Data Analytics. She previously worked at a Korean listed company and joined a Sports IP based blockchain project as market researcher. She is passionate in analyzing tokenomics of DeFi and DAOs.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-top: 100px;
  margin-bottom: 80px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
    min-width: 600px;

    @media (max-width: $max-mobile) {
      width: 100%;
      min-width: 0px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
</style>
